<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.customerId ? 'View/Edit' : 'Create'} ${this.type.charAt(0).toUpperCase() + this.type.slice(1)}`" @hidden="closeModal">
      <template #ok-button>
        <b-spinner v-if="saving" />
        <span v-if="!saving">{{ $route.params.customerId ? 'Update' : 'Create' }}</span>
      </template>

      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="6">
          <b-form-group label="Name" :invalid-feedback="getValidationMessage('name')" :state="!validationErrors.name">
            <b-form-input type="text" v-model="editedCustomer.name" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Company" :invalid-feedback="getValidationMessage('company')" :state="!validationErrors.company">
            <b-form-input type="text" v-model="editedCustomer.company" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="6">
          <b-form-group label="Email" :invalid-feedback="getValidationMessage('email')" :state="!validationErrors.email">
            <b-form-input type="email" v-model="editedCustomer.email" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone" :invalid-feedback="getValidationMessage('phone')" :state="!validationErrors.phone">
            <b-form-input type="tel" v-model="editedCustomer.phone" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Hourly Fee" :invalid-feedback="getValidationMessage('hourly_fee')" :state="!validationErrors.hourly_fee">
            <b-input-group prepend="£">
              <b-form-input type="number" step="0.01" v-model="editedCustomer.hourly_fee" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="border-top mt-2 pt-4" v-if="!loading && !error">
        <b-col cols="12">
          <p class="h5 mb-4 font-weight-bold">Address Information</p>
          <b-form-group label="Address Line 1" :invalid-feedback="join('; ', validationErrors['address.address_line_1'])" :state="!(validationErrors['address.address_line_1'])">
            <b-form-input type="text" v-model="editedCustomer.address.address_line_1" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Address Line 2" :invalid-feedback="join('; ', validationErrors['address.address_line_2'])" :state="!(validationErrors['address.address_line_2'])">
            <b-form-input type="text" v-model="editedCustomer.address.address_line_2" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="City" :invalid-feedback="join('; ', validationErrors['address.city'])" :state="!(validationErrors['address.city'])">
            <b-form-input type="text" v-model="editedCustomer.address.city" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="County" :invalid-feedback="join('; ', validationErrors['address.county'])" :state="!(validationErrors['address.county'])">
            <b-form-input type="text" v-model="editedCustomer.address.county" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Postcode" :invalid-feedback="join('; ', validationErrors['address.postcode'])" :state="!(validationErrors['address.postcode'])">
            <b-form-input type="text" v-model="editedCustomer.address.postcode" />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveCustomer" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.customerId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';

export default {
  mixins: [handleErrors],
  emits: ['hidden'],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedCustomer: {
        name: '',
        phone: '',
        email: '',
        company: '',
        hourly_fee: 0,
        address: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          state: '',
          postcode: '',
        }
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    customer: Object,
    id: String,
    type: { type: String, default: 'customers' },
  },
  async mounted() {
    if (this.$route.params.customerId && this.customer) {
      this.loading = true;

      try {
        const customer = await this.fetchSingle(this.$route.params.customerId);
        this.editedCustomer = {
          ...customer,
          address: customer.address ?? {},
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load customer. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('customers', ['fetch', 'fetchSingle', 'updateOrCreate']),
    join(join, arr) {
      if (arr && arr.length) {
        return arr.join(join)
      }

      return ''
    },
    closeModal() {
      this.validationErrors = {};
      this.editedCustomer = {
        address: {}
      };
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName});
      }
      this.$emit('hidden')
    },
    saveCustomer() {
      this.saving = true;
      this.updateOrCreate({
        ...this.editedCustomer,
        type: this.type
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    customer(value) {
      if (value === undefined) {
        return
      }

      this.editedCustomer = {
        ...value,
        address: value.address ?? {},
      };
    }
  }
}
</script>

<style>

</style>
